import axios from "axios";
export const api = axios.create({
    baseURL: "https://www.allexamtest.com"
    // baseURL:"http://localhost:9000",
})

export const baseURL = "https://www.allexamtest.com"
// export const baseURL = "http://localhost:9000"

export const uploadFile = async ({ endPoint, formData }) => {

    try {
        let response = await api.post(endPoint, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        return response.data
    } catch (error) {
        throw new Error(error.message || "Error in upload file")

    }
}